import Head from 'next/head';

export const GoogleSiteVerification = ({
  verificationId,
}: {
  verificationId?: string;
}) => {
  if (verificationId) {
    return (
      <Head>
        <meta name="google-site-verification" content={verificationId} />
      </Head>
    );
  } else {
    return null;
  }
};
