import type { PageProps } from 'components/App';
import ContentPage from 'components/ContentPage';
import { GoogleSiteVerification } from 'components/HomePage/GoogleSiteVerification';
import fetchContentPageBySlug from 'lib/contentful/fetchContentPageBySlug';
import { Page, sendOmetriaPageImpression } from 'lib/ometria';
import { ContentPageModel, HOMEPAGE_SLUG } from 'models/contentPages/types';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

const HomePage: FC<PageProps<ContentPageModel>> = props => {
  const { query } = useRouter();

  useEffect(
    sendOmetriaPageImpression({
      page: Page.Homepage,
      locale: query.locale as string,
    }),
    []
  );

  return (
    <>
      <GoogleSiteVerification
        verificationId={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID}
      />
      <ContentPage {...props} />
    </>
  );
};

export const getServerSideProps = fetchContentPageBySlug(HOMEPAGE_SLUG);

export default HomePage;
