import fetchContentPage from 'lib/contentful/fetchContentPage';
import { ContentPageModel } from 'models/contentPages/types';
import { GetServerSideProps } from 'next';

const fetchContentPageBySlug =
  (slug: string): GetServerSideProps<ContentPageModel & { origin: string }> =>
  async context =>
    await fetchContentPage({
      ...context,
      query: { ...context.query, products: slug },
    });

export default fetchContentPageBySlug;
